<template>
  <a-upload
    :action="action"
    :accept="accept"
    :name="name"
    :file-list="fileList"
    :multiple="multiple"
    :disabled="disabled"
    :customRequest="uploadSectionFile"
    :list-type="listType"
    :show-upload-list="showUploadList"
    :before-upload="beforeUpload"
    @preview="preView"
    @change="handleChange">
    <div v-if="fileList.length < maxFile">
      <a-icon :type="loading ? 'loading' : 'plus'"/>
      <div class="ant-upload-text">
        上传图片
      </div>
    </div>
  </a-upload>
</template>

<script>
import {upload} from "@/utils/request";

export default {
  name: 'ImgUploadList',
  components: {},
  props: {
    action: {
      type: String,
      default: null
    },
    accept: String,
    name: {
      type: String,
      default: 'file'
    },
    fileList: {
      type: Array,
      default() {
        return []
      }
    },
    beforeUpload: Function,
    preView: {
      type: Function,
      default: () => {
      }
    },
    handleChange: {
      type: Function,
      default: () => {
      }
    },
    multiple: Boolean,
    disabled: Boolean,
    listType: {
      type: String,
      default: 'picture-card'
    },
    showUploadList: {
      type: Boolean,
      default: false
    },
    image: String,
    maxFile: {
      type: Number,
      default: 4
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  mounted() {

  },
  methods: {
    uploadSectionFile(option) {
      if (!option.action) {
        this.$message.error('缺少action', 2)
        return
      }
      let formData = new FormData()
      if (option.data) {
        Object.keys(option.data).forEach(key => {
          formData.append(key, option.data[key])
        })
      }
      formData.append(option.filename, option.file, option.file.name)
      upload(option.action, formData, {
        onUploadProgress: e => {
          if (e.total > 0) {
            e.percent = e.loaded / e.total * 100
          }
          option.onProgress(e)
        }
      }).then(data => {
        this.loading = false
        this.$emit('success', data)
      }).finally(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style>

</style>
